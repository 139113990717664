/* light */
:root, html[data-theme='light'] {

  --contrasting-colour: #177ee6;

  --border-colour: #e6dcdc;
  --border-colour-error: #BB0000;
  --border-colour-form-field: #ccc;
  --border-colour-navbar-active: #73bcf7;
  --border-colour-navbar-hover: #777879;

  --background-colour-active: #fff;
  --background-colour-form-field: #f0f0f0;
  --background-colour-header: #151515;
  --background-colour-inactive: #f0f0f0;
  --background-colour-navbar: #212427;
  --background-colour-navbar-active: #4f5255;
  --background-colour-navbar-hover: #4f5255;

  --background-colour-ticket-comment-default: #177fe6a4;
  --background-colour-ticket-comment-notification: #96c7ff;
  --background-colour-ticket-comment-solution: #b7ff96;
  --background-colour-ticket-comment-task: #f8ff96;

  --background-colour-ticket-type-change: #badff7;
  --background-colour-ticket-type-incident: #f7baba;
  --background-colour-ticket-type-issue: #baf7db;
  --background-colour-ticket-type-request: #f7e9ba;
  --background-colour-ticket-type-problem: #f7d0ba;
  --background-colour-ticket-type-project-task: #c5baf7;

  --colour-status-ok: #319c3a;
  --colour-status-warn: #cf9925;
  --colour-status-bad: #cf3025;
  --colour-status-unk: #999;

  --text-colour: #000;
  --text-colour-contrasting: #FFF;
  --text-colour-button: #fff;
  --text-colour-error: #BB0000;
  --text-colour-footer: #999;
  --text-colour-header: #fff;
  --text-colour-help: #707070;
  --text-colour-inverted: #FFF;
  --text-colour-navbar: #fff;
  --text-colour-navtab: #6a6e73;
  --text-colour-software-action-add: #315f9c;
  --text-colour-software-action-install: #319c3a;
  --text-colour-software-action-remove: #9c3131;

}


/* AWX */
/* :root { */
html[data-theme='dark'] {

  --contrasting-colour: #177ee6;

  --border-colour: #444548;
  --border-colour-error: #BB0000;
  --border-colour-form-field: #444548;
  --border-colour-navbar-active: #73bcf7;
  --border-colour-navbar-hover: #777879;

  --background-colour-active: #1b1d21;
  --background-colour-form-field: #030303;
  --background-colour-header: #151515;
  --background-colour-inactive: #030303;
  --background-colour-navbar: #212427;
  --background-colour-navbar-active: #1b1d21;
  --background-colour-navbar-hover: #4f5255;

  --background-colour-ticket-comment-default: #177fe6a4;
  --background-colour-ticket-comment-notification: #96c7ffbe;
  --background-colour-ticket-comment-solution: #b7ff96be;
  --background-colour-ticket-comment-task: #f8ff96d3;

  --background-colour-ticket-type-change: #badff7c5;
  --background-colour-ticket-type-incident: #f7babac5;
  --background-colour-ticket-type-issue: #baf7dbc5;
  --background-colour-ticket-type-request: #f7e9bac5;
  --background-colour-ticket-type-problem: #f7d0bac5;
  --background-colour-ticket-type-project-task: #c5baf7c5;

  --colour-status-ok: #319c3a;
  --colour-status-warn: #cf9925;
  --colour-status-bad: #cf3025;
  --colour-status-unk: #999;

  --text-colour: #E0E0E0;
  --text-colour-contrasting: #E0E0E0;
  --text-colour-inverted: #0E0E0E;
  --text-colour-button: #E0E0E0;
  --text-colour-error: #BB0000;
  --text-colour-footer: #707070;
  --text-colour-header: #E0E0E0;
  --text-colour-help: #707070;
  --text-colour-navbar: #E0E0E0;
  --text-colour-navtab: #6a6e73;
  --text-colour-software-action-add: #315f9c;
  --text-colour-software-action-install: #319c3a;
  --text-colour-software-action-remove: #9c3131;

}


/* Dark */
/* :root {

  --contrasting-colour: #177ee6;

  --border-colour: #444548;
  --border-colour-error: #BB0000;
  --border-colour-form-field: #444548;
  --border-colour-navbar-active: #73bcf7;
  --border-colour-navbar-hover: #777879;

  --background-colour-active: #1b1d21;
  --background-colour-form-field: #26292d;
  --background-colour-header: #151515;
  --background-colour-inactive: #26292d;
  --background-colour-navbar: #212427;
  --background-colour-navbar-active: #1b1d21;
  --background-colour-navbar-hover: #4f5255;

  --background-colour-ticket-comment-default: #177fe6a4;
  --background-colour-ticket-comment-notification: #96c7ffbe;
  --background-colour-ticket-comment-solution: #b7ff96be;
  --background-colour-ticket-comment-task: #f8ff96d3;

  --text-colour: #E0E0E0;
  --text-colour-contrasting: #E0E0E0;
  --text-colour-inverted: #0E0E0E;
  --text-colour-button: #E0E0E0;
  --text-colour-error: #BB0000;
  --text-colour-footer: #707070;
  --text-colour-header: #E0E0E0;
  --text-colour-help: #707070;
  --text-colour-navbar: #E0E0E0;
} */





/* ############################################
#
#   Forms rewrite
#
############################################ */


button.align-right {
  float: right;
}


button.form {
  background-color: var(--contrasting-colour);
  color: var(--text-colour-button);
  border: none;
  font-size: inherit;
  height: 30px;
  margin: 5px;
  padding: 0px 20px;
}

button.inverse {
  background-color: var(--background-colour-active);
  color: var(--contrasting-colour);
}

button.mini {
  font-size: smaller;
  padding: 0px;
}

select,
textarea {
  border-color: var(--border-colour-form-field);
}

select {
  height: 30px;
}

select[multiple] {
  height: 200px;
}

input:invalid,
input[type=text]:invalid,
select:invalid,
textarea:invalid {
  border: 1px solid var(--border-colour-error);
}





/* ############################################
#
#   EoF Forms rewrite
#
############################################ */

* {
  margin: 0;
  padding: 0;
}

html {
  height: -webkit-fill-available;

  --font-size: 12pt;
  --line-height-common: 1.5rem;
}

body {
  background-color: var(--background-colour-inactive);
  color: var(--text-colour);
  font-family: Arial, Helvetica, sans-serif;
  font-size:  var(--font-size);
  width: 100%;
  line-height: var(--line-height-common);

}

a {
  color: var(--contrasting-colour);
  text-decoration: none;
}



fieldset {
  border: none;
  display: flex;
  text-align: left;
  flex-direction: column;
}

fieldset label {
  display: block;
  font-weight: bold;
  line-height: 1.5em;
}

fieldset span.text {
  font-size: 11pt;
  line-height: 1.5em;

}

fieldset span.error-text {
  display: block;
  color: var(--text-colour-error);
  font-size: 10pt;
  line-height: 1.5em;
  max-width: 400px;
  padding-left: .2em;
}

fieldset span.help-text {
  display: block;
  color: var(--text-colour-help);
  font-size: 10pt;
  line-height: 1.5em;
  padding-left: .2em;
}

fieldset.inline {
  display: flex;
  flex-direction: row;
  margin: 1rem 1rem;
}

fieldset.inline label {
  flex-grow: 0;
  text-align: right;
  padding-right: .5rem;
  width: 150px;
}

fieldset.textarea label {
  text-align: left;
  width: 100%;
}

fieldset.inline .text {
  border-bottom: 1px solid var(--border-colour-form-field);
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  width: 350px;
  word-wrap: break-word;
}


h1 {
  font-weight: bold;
  margin-bottom: 1rem;
}


h2 {
  font-weight: bold;
  margin-bottom: 1rem;
}


h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

h4 {
  font-weight: bold;
  margin-bottom: 1rem;
}



hr {
  border: none;
  border-bottom: 1px solid var(--border-colour);
  margin: 1rem 0;
}

p {
  line-height: var(--line-height-common);
  padding-bottom: 1rem;
}


header .icon {
  cursor: pointer;
  margin: 0px 20px;
}

header a {
  color: var(--text-colour-header);
  text-decoration: none;
}

header h1 {
  color: var(--text-colour-header);
  display: inline-block;
  height: 76px;
  line-height: 76px;
  margin: 0px;
  max-width: 275px;
  width: fit-content;
  padding: 0px;
  text-decoration: none;
}

header .right {
  display: flex;
  margin: 0 auto;
  margin-right: 5px;
}

header fieldset {
  border: none;
}




.app {

  height: 100%;
}

.icon-common {
  padding: 2px;
  margin: 0px;
  display: inline;
}

.icon-device-status-ok {
  fill: var(--colour-status-ok);
}

.icon-device-status-warn {
  fill: var(--colour-status-warn);
}

.icon-device-status-bad {
  fill: var(--colour-status-bad);
}

.icon-device-status-unk {
  fill: var(--colour-status-unk);
}

header {
  align-items: center;
  background-color: var(--background-colour-header);
  color: var(--text-colour-header);
  display: flex;
  height: 76px;
  margin: 0px;
  padding: 0px;
  position: sticky;
  text-align: center;
  top: 0px;
  vertical-align: middle;
  width: 100%;
}



.collapsible-hidden {
  display: none;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.collapsible-show {
  display: block;
  overflow: visible;
  transition: height 0.2s ease-in-out;
}


div.nav {
  bottom: 0px;
  height: calc(100vh - 76px);
  left: 0px;
  position: sticky;
  top: 76px;
  width: 275px;
}

nav {
  background-color: var(--background-colour-navbar);
  color: var(--text-colour-navbar);
  display: block;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

}


span.icon {
  display: flex;
}



nav .group {
  display: block;
  width: 276px;
}

nav .menu {
  cursor: pointer;
  display: flex;
  height: 30px;
  padding: 10px 0px;
  padding-left: 10px;
}

nav .menu:hover {
  font-weight: bold;
}

nav .menu span {
  display: inline-block;
  height: fit-content;
  margin: auto 0px;
  width: 100%;
}

nav .menu span.icon {
  height: 20px;
  margin: auto 5px;
  width: 20px;
}

nav .menu span.text {
  width: 100%;
}

nav .menu span.menu-icon {
  display: inline-block;
  height: 25px;
  margin: auto 0px;
  padding: 0px;
  width: 25px;
}

nav .sub-menu span.icon {
  height: 20px;
  margin: auto 5px;
  padding: 0px;
  width: 20px;
}

nav .page {
  cursor: pointer;
  display: flex;
  height: 30px;
  padding: 5px 0px;
}

nav .page.active {
  background-color: var(--background-colour-navbar-active);
  border-left: 3px solid var(--border-colour-navbar-active);
}

nav .page:hover {
  background-color: var(--background-colour-navbar-hover);
  border-left: 3px solid var(--border-colour-navbar-hover);
}

nav .page span {
  display: inline-block;
  height: fit-content;
  margin: auto 0px;
}

nav .page span.icon {
  margin-left: 30px;
  padding: auto;
  width: 20px;
}

.view-port {
  display: flex;
  height: 100%;
  padding: 0px;
  margin: 0;
  width: 100%;
}



svg.help {
  fill: var(--contrasting-colour);
}

svg.history {
  fill: #777;
}

svg.delete {
  fill: #ff0000;
}

span.sub-script {
  font-size: smaller;
}


.external-links a {
  color: var(--text-colour);
  text-decoration: none;
}


span.text-inline {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: var(--font-size);
  height: var(--line-height-common);
  vertical-align: middle;
  width: fit-content;
}

span.text-inline a {
  align-self: flex-end;
  display: inline-flex;
  flex-wrap: nowrap;
  height: var(--line-height-common);
  width: fit-content;
}

span.text-inline span.icon {
  align-self: center;
  display: inline-flex;
  flex-direction: row;
}

span.text-inline span.icon svg {
  align-self: center;
  display: flex;
  margin-right: .3rem;
}

span.text-inline span.text {
  margin-right: .3rem;
  width: fit-content;
  text-wrap: nowrap ;

}


span.text-inline span.sub-script {
  font-size: smaller;
  margin-right: .3em;
  color: var(--text-colour-help);
  vertical-align: bottom;
}



span.badge {
  background-color: var(--background-colour-active);
  border: 1px solid var(--border-colour-form-field);
  border-radius: 15px;
  display: inline-flex;
  font-size: 10pt;
  padding: 0px 3px;
  text-align: left;
  margin: auto 0px;
  vertical-align: bottom;
}

span.badge svg {
  margin: auto 0px;
}


span.badge span {
  line-height: normal;
}

span.badge.badge-icon {
  height: fit-content;
}

span.badge span.badge-text {
  background-color: var(--background-colour-active);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  height: fit-content;
  margin: 2px 2px;
  padding: 0 3px 0 2px;
}

a.badge-link {
  text-decoration: none;
  color: inherit;
}

span.badge.badge-icon.badge-icon-action-add {
  fill: var(--text-colour-software-action-add);
}

span.badge.badge-text.badge-text-action-add {
  color: var(--text-colour-software-action-add);
}

span.badge.badge-icon.badge-icon-action-install {
  fill: var(--text-colour-software-action-install);
}

span.badge.badge-text.badge-text-action-install {
  color: var(--text-colour-software-action-install);
}

span.badge.badge-icon.badge-icon-action-remove {
  fill: var(--text-colour-software-action-remove);
}

span.badge.badge-text.badge-text-action-remove {
  color: var(--text-colour-software-action-remove);
}






section {
  background-color: var(--background-colour-active);
  border: 1px solid var(--border-colour);
  padding: 0px;
  flex-grow: 1;
  margin: 0 .8rem .8rem .8rem;
}

section.error {
  display: flexbox;
  padding: 0px;
}

section.error h2,
section.error h3 {
  display: block;
  margin: 0px auto;
  text-align: center;
  width: 90%;
}

section.error p,
section.error pre {
  display: block;
  margin: 0px auto;
  width: fit-content;
  padding: 50px;
}

section .nav-tabs {
  background-color: var(--background-colour-active);
  border-bottom: 1px solid var(--border-colour);
  display: flex;
  height: 50px;

}

section .nav-tabs .nav-tabs-tab {
  border-bottom: 1px solid var(--border-colour);
  color: var(--text-colour-navtab);
  cursor: pointer;
  display: inline-flex;
  height: 50px;
  margin: auto 0px;
}

section .nav-tabs .nav-tabs-tab:hover {
  border-bottom: 3px solid var(--border-colour-navbar-hover);
}


section .nav-tabs .nav-tabs-tab.active {
  border-bottom: 3px solid var(--contrasting-colour);
}


section .nav-tabs .nav-tabs-tab div {
  display: flex;
  width: fit-content;
  margin: auto 0px;
  padding: 0% 10px;
}

section .nav-tabs .nav-tabs-tab span {
  display: inline-block;
  height: fit-content;
  margin: auto 0px;
  padding: 0%;
}


section .nav-tabs .nav-tabs-tab .nav-tabs-icon {
  color: var(--background-colour-active);
  height: 25px;
  width: 25px;
  margin: auto 0px;
}

section .nav-tabs .nav-tabs-tab .nav-tabs-icon svg {
  fill: var(--text-colour-navtab);
  height: 25px;
  width: 25px;
}

section .nav-tabs .nav-tabs-tab .nav-tabs-text {
  font-size: 13pt;
}


section .content table {
  width: 100%;
}

section .content table tr.collapsible-row {
  height: fit-content;
  text-align: left;
}

section .content table tr.collapsible-row td {
  height: fit-content;
  padding: 0px;
}

section .content table tr td svg.navdown {
  fill: var(--background-colour-navbar-active);
  width: 30px;
  height: 30px;
}
section .content table tr td div {
  display: flex;
}
section .content table tr td .hide-expandable-row {
  display: none;
}

section .content table tr div.dual-column {
  display: inline-block;
  height: fit-content;
  margin: auto;
  width: fit-content;
}

section .content thead,
section .content tr {
  text-align: center;
  height: 60px;
}

section .content th,
section .content td {
  border-bottom: 1px solid var(--border-colour);
}




textarea#model-note {
  width: 40%;
  height: 100px;
  margin: 0px auto;
}

textarea.large {
  width: 900px;
}


section .model-note div.single-note {
  background-color: var(--background-colour-active);
  border: 1px solid var(--border-colour);
  padding: 0px;
  display: block;
}


div.cards {
  display: flex;
  flex-wrap:wrap;
  justify-content:left;
  padding: 10px;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

section.card {
  align-self: top;
  display: flexbox;
  margin: 20px;
  max-width: 40%;
  min-width: 200px;
  padding: 0px 20px;
  text-align: left;
  width: calc(30% - 40px);

}

section.card h3 {
  background-color: var(--contrasting-colour);
  color: var(--text-colour-button);
  margin: -20px -20px 20px -20px;
  padding: 2px 10px;
  text-align: center;
}

.model-note div.comment-header {
  background-color: #aaa;
  color: var(--text-colour-button);
  margin: 0px;
  padding: 2px 10px;
}

.model-note div.comment-body {
  margin: 0px;
  padding: 0px 10px;
}

.model-note div.comment-footer {
  color: var(--text-colour);
  margin: 0px;
  padding: 2px 10px;
}









p.table-pagination {
  display: inline-block;
  padding: 0px
}

p.table-pagination span {
  display: inline-block;
  margin: 0px 10px;
}

p.table-pagination span.table-pagination-text {
}

p.table-pagination span.table-pagination-text input {
  width: 40px;
  text-align: center;
  margin: 0px 5px;
}


p.table-pagination span.table-pagination-button {
  cursor: pointer;
}

form {
  display: flexbox;
}

.common-field {
  background-color: var(--background-colour-form-field);
  color: var(--text-colour);
  line-height: 30px;
}


input.common-field {
  border: none;
  border-bottom: 1px solid var(--border-colour-form-field);
}

input {
  font-family: inherit;
  font-size: 12pt;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
     margin: 0; 
}


textarea {
  font-family: inherit;
  font-size: 12pt;
}

.content form fieldset .common-field {
  min-width: none;
}


div.align-center {
  text-align: center;
}

div.align-right {
  display: block;
  padding: 0px;
  margin: auto;
  margin-right: 5px;
  margin-bottom: 5px;
  width: fit-content;
}

div.submit-dropdown-button {
  display: inline-flex;
}

div.submit-dropdown-button button.button,
div.submit-dropdown-button div.button {
  background-color: var(--contrasting-colour);
  border: none;
  color: var(--text-colour-button);
  display: inline-block;
  font-size: inherit;
  height: 30px;
  padding: 0px 20px;

}


div.menu .dropdown-menu { /* Default, Hide menu. */
  display: none;
}

div.menu .dropdown-menu.show {
  display: block;
}


div.menu .dropdown-menu-items a,
div.menu .dropdown-menu-items div,
div.menu .dropdown-menu-items span {
  background-color: var(--background-colour-active);
  margin: 0.1rem 0;
  padding: 0.1rem;
  color: var(--text-colour);
  line-height: 2rem;
}

div.menu .dropdown-menu-items a:hover,
div.menu .dropdown-menu-items div:hover,
div.menu .dropdown-menu-items span:hover {
  background-color: var(--background-colour-navbar-hover);
  border-left: 3px solid var(--border-colour-navbar-active);
  color: var(--text-colour-navbar);
}


img.avatar {
  align-self:flex-end;
  background-color: #7e7e7e36;
  border: none;
  border-radius: 50%;
  padding: 3px;
  height: 32px;
  width: 32px;
}


footer {
  background-color: var(--background-colour-active);
  display: grid;
  gap: 0px;
  grid-template-columns: 1fr 1fr 1fr;
  height: 76px;
  width: 100%;
}

footer .column {
  margin: auto 0px;
  text-align: center;
}

footer .left {
  display: block;
  align-items: left;
  text-align: left;
  padding-left: 10px;
}

footer .footer-icons svg {

  fill: var(--contrasting-colour);
  height: 30px;
  width: 30px;
  margin: 0px 3px;

}


footer .center {
  align-items: center;
  color: var(--text-colour-footer);
  font-size: 9pt;
}

footer .right {
  display: block;
  align-items: right;
  text-align: right;
  padding: 25px;
  color: var(--text-colour-footer);
  font-size: 9pt;
}


/* The switch - the box around the slider */
.switch {
  position: relative;
  display: block;
  width: 52px;
  height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--border-colour-form-field);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: var(--contrasting-colour);
}

input:focus+.slider {
  box-shadow: 0 0 1px var(--contrasting-colour);
}

input:checked+.slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}






section div.tabs {
  border-bottom: 1px solid var(--border-colour);
  display: flex;
  height: 30px;

}

section div.tabs span.tab {
  border-bottom: 3px solid transparent;
  color: var(--text-colour-navtab);
  cursor: pointer;
  display: inline-flex;
  margin: auto 0px;
  margin-bottom: 0px;
  padding: 5px 10px 5px 10px;
}

section div.tabs span.tab:hover {
  border-bottom: 3px solid var(--border-colour-navbar-hover);
}


section div.tabs span.tab.active {
  border-bottom: 3px solid var(--contrasting-colour);
}


textarea {
  display: flex;
  margin: 0px;
  padding: 5px;
  width: calc(100% - 12px);
  
}


.md-editor {
  display: flexbox;
  padding: 5px;
}



div.testing {

  background-color: #a7c8e7;
  height: 400px;
  font-size: inherit;
}

div.testing p {
  background-color: #73cc73;
  font-size: inherit;
}


.testing span.inline {
  background-color: #00f;
  display: inline-flex;
  margin: auto 0px;
  height: 25px;
  vertical-align: bottom;
}

.testing span.inline .text {
  background-color: #cf9925;
}

.testing span.inline svg {
  margin: auto 0px;
}



/* *******************************************

CSS Rewrite (mobile readyness)

******************************************* */


div.content .header .column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-width: 300px;
  text-align: center;

}

div.content .header .column.left {
  padding-left: 1rem;
  text-align: left;
}

div.content .header .column.right {
  padding-right: 1rem;
  text-align: right;
}

.content .header span.inline.icon {
  display: flex;
  flex-direction: row;
  text-align: right;
  align-self: self-end;
}

.content .header .icon svg {
  width: 1.9rem;
  height: 1.9rem;
  margin: 0 3px;
}





.badge.status svg {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}


.badge.status.very-low {
  color: #008cff;
}

.badge.status.very-low svg {
  background-color: #008cff83;
  fill: #008cff;
}

.badge.status.low svg {
  background-color: #00921383;
  fill: #009213;
}

.badge.status.low {
  color: #009213;
}

.badge.status.medium svg {
  background-color: #fbff0083;
  fill: #fbff00;
}

.badge.status.med {
  color: #fbff00;
}

.badge.status.high svg {
  background-color: #ff880083;
  fill: #ff8800;
}

.badge.status.high {
  color: #ff8800;
}

.badge.status.very-high svg {
  background-color: #ff000083;
  fill: #ff0000;
}

.badge.status.very-high {
  color: #ff0000;
}



/* ###################################################################

ticket fixed

   ################################################################### */


section h3 span {
  min-width: fit-content;
}





/* ###################################################################

fixme

################################################################### */




div.submit-dropdown-button div.dropdown-button {
  height: 30px;
  margin: 0px;
  margin-left: 1px;
  padding: 0px;
}

div.submit-dropdown-button div.button svg {
  display: inline-flex;
  height: 30px;
  margin: 0px;
  padding: 0px;
  width: 30px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--background-colour-active);
  margin-top: 30px;
  min-width: 140px;
  width: auto;
  z-index: 1;
}


.dropdown-menu-entry {
  background-color: var(--background-colour-active);
  border: none;
  color: var(--text-colour);
  margin: 1px;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
  font-size: 12pt;
  width: 100%;
}

.dropdown-content p:hover, .dropdown-menu-entry:hover {
  background-color: var(--background-colour-navbar-hover);
  color: var(--text-colour-button);
}

.show {
  display:block;
}




span.badge-icon.ticket-status-icon svg,
span.badge.badge-icon.ticket-status-icon svg {
  border-radius: 10px;
}

span.badge-icon.ticket-status-icon-accepted svg,
span.badge.badge-icon.ticket-status-icon-accepted svg {
  background-color: #e1ffb2;
  fill: #2e9200;
}

span.badge-icon.ticket-status-icon-approvals svg,
span.badge.badge-icon.ticket-status-icon-approvals svg {
  background-color: #ffceb2;
  fill: #d86100;
}

span.badge-icon.ticket-status-icon-assigned svg,
span.badge.badge-icon.ticket-status-icon-assigned svg {
  background-color: #e1ffb2;
  fill: #2e9200;
}

span.badge-icon.ticket-status-icon-assigned_planning svg,
span.badge.badge-icon.ticket-status-icon-assigned_planning svg {
  background-color: #e1ffb2;
  fill: #2e9200;
}

span.badge-icon.ticket-status-icon-closed svg,
span.badge.badge-icon.ticket-status-icon-closed svg {
  background-color: #c9b2ff;
  fill: #640092;
}

span.badge-icon.ticket-status-icon-draft svg,
span.badge.badge-icon.ticket-status-icon-draft svg {
  background-color: #cacaca;
  fill: #4d4d4d;
}

span.badge-icon.ticket-status-icon-evaluation svg,
span.badge.badge-icon.ticket-status-icon-evaluation svg {
  background-color: #b2d6ff;
  fill: #007592;
}

span.badge-icon.ticket-status-icon-invalid svg,
span.badge.badge-icon.ticket-status-icon-invalid svg {
  background-color: #ffb2b6;
  fill: #920000;
}

span.badge-icon.ticket-status-icon-new svg,
span.badge.badge-icon.ticket-status-icon-new svg {
  background-color: #b2dcff;
  fill: #004492
}

span.badge-icon.ticket-status-icon-pending svg,
span.badge.badge-icon.ticket-status-icon-pending svg {
  background-color: #ffceb2;
  fill: #d86100;
}

span.badge-icon.ticket-status-icon-solved svg,
span.badge.badge-icon.ticket-status-icon-solved svg {
  background-color: #c9b2ff;
  fill: #640092;
}

span.badge-icon.ticket-status-icon-testing svg,
span.badge.badge-icon.ticket-status-icon-testing svg {
  background-color: #b2d3ff;
  fill: #8c00ff;
}



